<template>
	<div>
		<h1 class="main-title mb-3">Запросы, которые не сохранились в CH</h1>
		<div class="d-flex">
			<v-spacer />
			<v-select
				class="mr-3 not-saved-filter-only-out"
				:value="urlFilterOnlyOut"
				:items="optionsOnlyOut"
				outlined
				dense
				hide-details
				@input="updateOnlyOut"
			/>
			<v-resave-queries-button
				all
				showText
				:projectId="+$route.params.id"
				:searchSystem="$route.query.search_system"
				:date="$route.query.date"
			/>
		</div>
		<v-data-table
			class="not-saved-ch__table"
			:loading="isLoading"
			:headers="headers"
			:items="notSavedData"
			:items-per-page="-1"
			hide-default-footer
		>
			<template v-slot:item.index="{ index }">
				{{ index + offset + 1 }}
			</template>
			<template v-slot:item.is_out="{item}">
				{{ item.is_out ? 'Да' : 'Нет' }}
			</template>
			<template v-slot:item.query="{ item }">
				<div class="d-flex justify-space-between align-center">
					<span>{{ item.query }}</span>
					<v-resave-queries-button
						:query="item.query"
						:projectId="+$route.params.id"
						:searchSystem="$route.query.search_system"
						:date="$route.query.date"
					/>
				</div>

			</template>
		</v-data-table>
		<div class="not-saved-ch__pagination" v-if="showPagination">
			<p class="not-saved-ch__info">Максимум на странице: {{ limit }}</p>
			<v-pagination :value="page" :length="totalPages" :total-visible="visiblePagesCount" v-on:input="handlePageChange"></v-pagination>
		</div>
	</div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

import resaveQueriesButton from '../components/resaveQueriesButton.vue'

export default {
	components: {
		'v-resave-queries-button': resaveQueriesButton
	},
	data () {
		return {
			limit: 100,
			page: 1,
			nextPage: 1,
			totalPages: 1,
			visiblePagesCount: 6,
			isLoading: false,
			headers: [
				{
					text: '№',
					value: 'index',
					sortable: false,
					align: 'center',
					width: '5%',
					divider: true
				},
				{
					text: 'Out',
					value: 'is_out',
					sortable: true,
					divider: true
				},
				{
					text: 'Запрос',
					value: 'query',
					sortable: false,
					divider: true
				}
			],
			optionsOnlyOut: [
				{
					value: 0,
					text: 'Все'
				},
				{
					value: 1,
					text: 'Только Out'
				}
			]
		}
	},

	computed: {
		...mapGetters({
			notSavedData: 'save/notSavedQueriesCh'
		}),

		query () {
			return {
				params: {
					project_id: this.$route.params.id,
					source: this.$route.query.search_system,
					date: this.$route.query.date,
					only_out: this.urlFilterOnlyOut
				},
				pagination: {
					limit: this.limit,
					page: this.page
				}
			}
		},
		offset () {
			return this.page * this.limit - this.limit
		},
		isLimitReached () {
			return this.notSavedData.length >= this.limit
		},
		showPagination () {
			return this.isLimitReached || this.totalPages > 1
		},
		urlFilterOnlyOut () {
			const only_out = +this.$route.query.only_out

			if (!isNaN(only_out)) {
				const option = this.optionsOnlyOut.find(item => item.value === only_out)

				return option ? option.value : 0
			}

			return 0
		}
	},

	watch: {
		nextPage (newValue) {
			this.totalPages = Math.max(this.totalPages, newValue)
		},

		async urlFilterOnlyOut () {
			await this.fetchData()
		}
	},

	methods: {
		...mapActions({
			getNotSavedData: 'save/getNotSavedQueriesCH'
		}),

		async fetchData () {
			try {
				this.isLoading = true
				await this.getNotSavedData(this.query)
			} catch (error) {
				this.$notify({ type: 'error', title: error })
			} finally {
				this.isLoading = false
			}
		},

		updateNextPage () {
			this.nextPage = this.isLimitReached ? this.page + 1 : this.page
		},

		async handlePageChange (newValue) {
			if (this.page !== newValue) {
				this.page = newValue
				await this.fetchData()
				this.updateNextPage()
			}
		},

		async updateOnlyOut (only_out) {
			await this.$router.push({
				query: {
					...this.$route.query,
					only_out
				}
			})
		}
	},

	async mounted () {
		await this.fetchData()
		this.updateNextPage()
	}
}
</script>

<style>
.not-saved-ch__table {
	margin-top: 30px;
	margin-bottom:30px;
}

.not-saved-ch__info {
	text-align: center;
}

.not-saved-ch__pagination {
	position: fixed;
	left: 50%;
	bottom: 20px;
	min-width: 370px;
	transform: translateX(-50%);
}
.not-saved-filter-only-out {
	max-width: 200px;
}
</style>
